<template>
    <v-container :class="{ 'fill-height': !selectedUser }">


        <!-- User Selection Card - centered -->
        <v-row v-if="!selectedUser" justify="center" align="center">
            <v-col cols="12" sm="8" md="6" lg="5">
                <v-card elevation="2" class="rounded-lg overflow-hidden" max-width="500px">
                    <v-card-title>
                        <v-icon left color="primary">mdi-account</v-icon>
                        Seleccionar Cliente
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="pa-0">
                        <v-text-field v-model="search" label="Buscar" prepend-inner-icon="mdi-magnify" filled
                            hide-details="" class="rounded-0" clearable></v-text-field>

                        <v-data-table :headers="userHeaders" :items="users" :search="search" :loading="loading.users"
                            elevation="1" mobile-breakpoint="0" height="300px" single-select show-select
                            v-model="selectedUsers" hide-default-footer class="mobile-full-width">
                            <template v-slot:header.id="{ header }">
                                <v-icon small left>{{ header.icon }}</v-icon>
                                {{ header.text }}
                            </template>
                            <template v-slot:header.displayName="{ header }">
                                <v-icon small left>{{ header.icon }}</v-icon>
                                {{ header.text }}
                            </template>
                        </v-data-table>
                    </v-card-text>

                    <v-divider />
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="rounded-lg" color="primary" :disabled="!selectedUsers.length"
                            @click="confirmUserSelection">
                            <v-icon left>mdi-chevron-right</v-icon>
                            Continuar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- Main Content - normal flow -->
        <template v-else>
            <v-card elevation="2" class="rounded-lg overflow-hidden">
                <v-card-title>
                    <v-icon left color="primary">mdi-cash-register</v-icon>
                    Punto de Venta
                    <v-spacer></v-spacer>
                    <v-btn @click="resetSelection" class="rounded-lg" elevation="2">
                        Volver a selección
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-0">
                    <v-row>
                        <!-- Items Grid -->
                        <v-col cols="12" md="8">
                            <v-tabs v-model="activeTab">
                                <v-tab>
                                    <v-icon left>mdi-store</v-icon>
                                    Productos
                                </v-tab>
                                <v-tab>
                                    <v-icon left>mdi-card-account-details</v-icon>
                                    Subscripciones
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="activeTab">
                                <v-tab-item>
                                    <v-divider />
                                    <v-card>
                                        <v-card-subtitle>
                                            <v-text-field dense filled rounded v-model="searchProducts"
                                                append-icon="mdi-magnify" class="rounded-lg" label="Buscar productos"
                                                single-line hide-details clearable></v-text-field>
                                        </v-card-subtitle>
                                        <v-divider />
                                        <v-card-text class="outlined">
                                            <template v-if="isSmallScreen">
                                                <v-list v-if="filteredItems.length > 0">
                                                    <template v-for="item in filteredItems">
                                                        <v-list-item :key="item.id" @click="addToCart(item)"
                                                            @dblclick="addToCart(item)">
                                                            <v-list-item-avatar>
                                                                <v-img v-if="item.image" :src="item.image" height="60"
                                                                    contain></v-img>
                                                                <v-icon v-else size="40"
                                                                    color="grey lighten-1">mdi-image-off</v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    {{ item.stock > 0 ? `${item.stock} disponibles` :
                                                                        'Sin
                                                                    stock' }}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                            <v-list-item-action>
                                                                <v-chip small color="primary">${{ item.price }}</v-chip>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                        <v-divider />
                                                    </template>
                                                </v-list>
                                                <div v-else class="d-flex flex-column align-center py-8">
                                                    <v-icon size="100" color="grey lighten-1">mdi-store-off</v-icon>
                                                    <span class="text-subtitle-1 grey--text text--darken-1 mt-4">
                                                        No hay productos disponibles
                                                    </span>
                                                    <span class="text-caption grey--text mt-1" v-if="searchProducts">
                                                        Intenta con otra búsqueda
                                                    </span>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <v-row v-if="filteredItems.length > 0">
                                                    <v-col v-for="item in filteredItems" :key="item.id" cols="12" sm="6"
                                                        md="4" lg="4">
                                                        <v-hover v-slot="{ hover }">
                                                            <v-card @click="addToCart(item)" :elevation="hover ? 8 : 2">
                                                                <div class="position-relative">
                                                                    <v-img v-if="item.image" :src="item.image"
                                                                        height="200" contain></v-img>
                                                                    <v-sheet v-else height="200"
                                                                        class="d-flex align-center justify-center">
                                                                        <v-icon size="100"
                                                                            color="grey">mdi-image-off</v-icon>
                                                                    </v-sheet>

                                                                    <!-- Hover overlay -->
                                                                    <div v-if="hover"
                                                                        class="hover-overlay d-none d-md-flex">
                                                                        <div class="text-center white--text">
                                                                            <v-icon x-large
                                                                                color="white">mdi-plus-circle</v-icon>
                                                                            <div class="mt-2">Agregar</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <v-divider />
                                                                <v-card-title class="subtitle-1">{{ item.name
                                                                    }}</v-card-title>
                                                                <v-card-text>
                                                                    <div class="d-flex justify-space-between">
                                                                        <span class="caption">
                                                                            {{ item.stock > 0 ? `${item.stock}
                                                                            disponibles` : 'Sin stock' }}
                                                                        </span>
                                                                        <v-chip small color="primary">${{ item.price
                                                                            }}</v-chip>
                                                                    </div>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-hover>
                                                    </v-col>
                                                </v-row>
                                                <div v-else class="d-flex flex-column align-center py-8">
                                                    <v-icon size="100" color="grey lighten-1">mdi-store-off</v-icon>
                                                    <span class="text-subtitle-1 grey--text text--darken-1 mt-4">
                                                        No hay productos disponibles
                                                    </span>
                                                    <span class="text-caption grey--text mt-1" v-if="searchProducts">
                                                        Intenta con otra búsqueda
                                                    </span>
                                                </div>
                                            </template>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>

                                <v-tab-item>
                                    <v-divider />

                                    <v-card>
                                        <v-card-subtitle>
                                            <v-icon left color="primary">mdi-card-account-details</v-icon>
                                            Selecciona una subscripción
                                        </v-card-subtitle>
                                        <v-divider />
                                        <v-card-text>
                                            <Picker @subscription-selected="addSubscriptionToCart" />
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>

                        <!-- Cart -->
                        <v-col cols="12" md="4">
                            <v-card class="sticky-cart ma-4" outlined>
                                <v-card-subtitle dense>
                                    <v-icon left color="primary">mdi-cart</v-icon>
                                    Carrito
                                </v-card-subtitle>

                                <v-divider />

                                <v-card-text style="max-height: 50vh; overflow-y: auto;">
                                    <v-list v-if="cart.length > 0">
                                        <v-list-item v-for="item in cart" :key="item.id">
                                            <v-list-item-avatar size="60" class="mr-3">
                                                <v-img v-if="item.image" :src="item.image" contain></v-img>
                                                <v-icon v-else size="40" color="grey lighten-1">mdi-image-off</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    ${{ item.price }} x {{ item.quantity }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <div class="d-flex align-center">
                                                    <template v-if="!item.isSubscription">
                                                        <v-btn icon x-small @click="decrementQuantity(item)">
                                                            <v-icon>mdi-minus</v-icon>
                                                        </v-btn>
                                                        <span class="mx-2">{{ item.quantity }}</span>
                                                        <v-btn icon x-small @click="incrementQuantity(item)">
                                                            <v-icon>mdi-plus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <template v-else>
                                                        <v-btn icon x-small @click="removeFromCart(item)" color="error">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                        <span class="mx-2">1</span>
                                                    </template>
                                                </div>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <div v-else class="d-flex flex-column align-center py-8">
                                        <v-icon size="100" color="grey lighten-1">mdi-cart-outline</v-icon>
                                        <span class="text-subtitle-1 grey--text text--darken-1 mt-4">
                                            El carrito está vacío
                                        </span>
                                    </div>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-text>
                                    <div class="d-flex justify-space-between">
                                        <strong>Total:</strong>
                                        <strong>${{ total }}</strong>
                                    </div>

                                    <v-divider class="mt-4" />
                                    <v-list-item two-line>
                                        <v-list-item-avatar>
                                            <UserImage small :user="selectedUser" />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ selectedUser.displayName }} </v-list-item-title>
                                            <v-list-item-subtitle>
                                                ID: {{ selectedUser.id }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />


                                </v-card-text>

                                <v-card-actions>
                                    <v-btn color="error" text @click="clearCart" :disabled="cart.length === 0">
                                        Limpiar Carrito
                                    </v-btn>

                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" :disabled="cart.length === 0" :loading="loading.checkout"
                                        @click="showCheckoutConfirmation">
                                        <v-icon left>mdi-cash-register</v-icon>
                                        Pagar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>


            </v-card>

        </template>

        <!-- Checkout Confirmation Dialog -->
        <v-dialog v-model="checkoutDialog" max-width="400px">
            <v-card>
                <v-card-title class="headline">
                    <v-icon left color="primary">mdi-cash-register</v-icon>
                    Confirmar Compra
                </v-card-title>
                <v-card-text>
                    <p class="mb-2">¿Está seguro que desea completar esta compra?</p>

                    <!-- Add payment method selection -->
                    <v-radio-group v-model="paymentMethod" row dense
                        :rules="[(v) => !!v || 'Payment method is required']">
                        <v-radio label="TARJETA" value="TARJETA"></v-radio>
                        <v-radio label="EFECTIVO" value="EFECTIVO"></v-radio>
                        <v-radio label="TRANSFERENCIA" value="TRANSFERENCIA"
                            v-if="$store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin'">
                        </v-radio>
                        <v-radio label="LINK" value="LINK"
                            v-if="$store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin'">
                        </v-radio>
                    </v-radio-group>

                    <!-- Card type selection (show only when TARJETA is selected) -->
                    <template v-if="paymentMethod === 'TARJETA'">
                        <v-radio-group v-model="cardType" row dense :rules="[(v) => !!v || 'Card type is required']">
                            <v-radio label="VISA" value="VISA"></v-radio>
                            <v-radio label="MASTER" value="MASTER"></v-radio>
                            <v-radio label="OCA" value="OCA"></v-radio>
                            <v-radio label="MAESTRO" value="MAESTRO"></v-radio>
                            <v-radio label="AMEX" value="AMEX"></v-radio>
                        </v-radio-group>

                        <v-radio-group v-model="cardPaymentType" row dense
                            :rules="[(v) => !!v || 'Payment type is required']">
                            <v-radio label="CREDITO" value="CREDITO"></v-radio>
                            <v-radio label="DEBITO" value="DEBITO"></v-radio>
                        </v-radio-group>
                    </template>

                    <div class="d-flex justify-space-between subtitle-1 mt-4">
                        <strong>Monto Total:</strong>
                        <strong class="primary--text">${{ total }}</strong>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="checkoutDialog = false">Cancelar</v-btn>
                    <v-btn color="primary" :loading="loading.checkout" :disabled="!isPaymentMethodValid"
                        @click="confirmCheckout">
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Snackbar -->
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000" top>
            {{ snackbar.text }}
            <template #action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar.show = false">Cerrar</v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import {
    getFirestore,
    collection,
    query,
    where,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    getDoc,
    serverTimestamp,
} from 'firebase/firestore';
import Picker from '@/components/subscriptions/Picker.vue';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';
import UserImage from '@/components/profile/UserImage.vue';
import { logAuditEvent } from '@/error/audit';

export default {
    name: 'PuntoVenta',
    components: {
        Picker,
        UserImage
    },
    data: () => ({
        db: getFirestore(),
        selectedUsers: [],
        selectedUser: null,
        users: [],
        items: [],
        cart: [],
        search: '',
        searchProducts: '',
        loading: {
            users: false,
            items: false,
            checkout: false,
        },
        userHeaders: [
            {
                value: 'id',
                align: 'start',
                sortable: true,
                icon: 'mdi-identifier'
            },
            {
                text: 'Nombre',
                value: 'displayName',
                align: 'start',
                sortable: true,
                icon: 'mdi-account'
            }
        ],
        snackbar: {
            show: false,
            text: '',
            color: 'success'
        },
        checkoutDialog: false,
        activeTab: 0,
        paymentMethod: null,
        cardType: null,
        cardPaymentType: null,
    }),

    computed: {
        filteredItems() {
            if (!this.searchProducts) return this.items;
            const search = this.searchProducts.toLowerCase();
            return this.items.filter(item =>
                item.name.toLowerCase().includes(search)
            );
        },
        total() {
            return this.cart
                .reduce((sum, item) => sum + (item.price * item.quantity), 0)
                .toFixed(2);
        },
        isPaymentMethodValid() {
            if (!this.paymentMethod) return false;
            if (this.paymentMethod === 'TARJETA') {
                return this.cardType && this.cardPaymentType;
            }
            return true;
        },
        isSmallScreen() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },

    created() {
        // Check if there's a user ID in the route
        const userId = this.$route.params.userId;
        if (userId) {
            this.loadUserFromId(userId);
        }
        this.fetchUsers();
        this.fetchItems();
    },

    methods: {
        async loadUserFromId(userId) {
            this.loading.users = true;
            try {
                const userRef = doc(this.db, 'users', userId);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists() && !userDoc.data().disabled) {
                    this.selectedUser = { id: userDoc.id, ...userDoc.data() };
                    logAuditEvent('read', this.$store.state.Auth.token.claims.user_id,
                        `Loaded user ${userId} for point of sale`);
                } else {
                    this.$notify({
                        title: 'Error',
                        message: 'Usuario no encontrado o deshabilitado',
                        type: 'error'
                    });
                    logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
                        `Failed to load user ${userId} - not found or disabled`);
                }
            } catch (error) {
                console.error('Error al cargar usuario:', error);
                this.$notify({
                    title: 'Error',
                    message: 'Error al cargar usuario',
                    type: 'error'
                });
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
                    `Error loading user ${userId}: ${error.message}`);
            } finally {
                this.loading.users = false;
            }
        },

        async fetchUsers() {
            this.loading.users = true;
            try {
                const usersRef = collection(this.db, 'users');
                const q = query(usersRef, where('disabled', '==', false));
                const querySnapshot = await getDocs(q);
                this.users = [];
                querySnapshot.forEach((doc) => {
                    this.users.push({ id: doc.id, ...doc.data() });
                });
            } catch (error) {
                console.error('Error al cargar usuarios:', error);
                this.$notify({
                    title: 'Error',
                    message: 'Error al cargar usuarios',
                    type: 'error'
                });
            } finally {
                this.loading.users = false;
            }
        },

        async fetchItems() {
            this.loading.items = true;
            try {
                const itemsRef = collection(this.db, 'items');
                const q = query(itemsRef,
                    where('deleted', '==', false),
                    where('visibleAtStore', '==', true)
                );
                const querySnapshot = await getDocs(q);
                this.items = [];
                querySnapshot.forEach((doc) => {
                    const item = doc.data();
                    if (item.stock > 0) {
                        this.items.push({ id: doc.id, ...item });
                    }
                });
                logAuditEvent('read', this.$store.state.Auth.token.claims.user_id,
                    'Fetched store items');
            } catch (error) {
                console.error('Error al cargar productos:', error);
                this.$notify({
                    title: 'Error',
                    message: 'Error al cargar productos',
                    type: 'error'
                });
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
                    `Error fetching items: ${error.message}`);
            } finally {
                this.loading.items = false;
            }
        },

        confirmUserSelection() {
            if (this.selectedUsers.length) {
                this.selectedUser = this.selectedUsers[0];
                // Add user ID to URL
                this.$router.push({
                    name: 'punto-venta',
                    params: { userId: this.selectedUser.id }
                });
            }
        },

        addToCart(item) {
            if (item.stock <= 0) {
                this.$notify({
                    title: 'Error',
                    message: 'Producto sin stock',
                    type: 'error'
                });
                return;
            }

            const existingItem = this.cart.find(cartItem => cartItem.id === item.id);
            if (existingItem) {
                if (existingItem.quantity < item.stock) {
                    existingItem.quantity++;
                } else {
                    this.$notify({
                        title: 'Error',
                        message: 'No hay suficiente stock disponible',
                        type: 'error'
                    });
                }
            } else {
                this.cart.push({
                    id: item.id,
                    name: item.name,
                    price: parseFloat(item.price),
                    quantity: 1,
                    stock: item.stock,
                    image: item.image
                });
            }
        },

        incrementQuantity(item) {
            if (item.quantity < item.stock) {
                item.quantity++;
            } else {
                this.$notify({
                    title: 'Error',
                    message: 'No hay suficiente stock disponible',
                    type: 'error'
                });
            }
        },

        decrementQuantity(item) {
            if (item.quantity > 1) {
                item.quantity--;
            } else {
                this.cart = this.cart.filter(cartItem => cartItem.id !== item.id);
            }
        },

        removeFromCart(item) {
            this.cart = this.cart.filter(cartItem => cartItem.id !== item.id);
        },

        clearCart() {
            this.cart = [];
        },

        showCheckoutConfirmation() {
            this.checkoutDialog = true;
        },

        async confirmCheckout() {
            this.checkoutDialog = false;
            await this.checkout();
        },

        async checkout() {
            this.loading.checkout = true;
            try {
                let totalRevenue = 0;
                let totalWithoutSubscription = 0;
                let subscriptionPrice = 0;

                // Process each cart item
                for (let cartItem of this.cart) {
                    if (cartItem.isSubscription) {
                        // Handle subscription
                        const paymentData = {
                            id: this.selectedUser.id,
                            amount: cartItem.subscriptionData.days,
                            createdAt: serverTimestamp(),
                            months: cartItem.subscriptionData.months,
                            endOfSubscription: this.calculateEndOfSubscription(cartItem.subscriptionData),
                            payMethod: this.paymentMethod,
                            cardType: this.paymentMethod === 'TARJETA' ?
                                `${this.cardPaymentType} ${this.cardType}` :
                                null,
                            newSubscription: false,
                            paymentProcessedBy: this.$store.state.Auth.token.claims.name || this.$store.state.Auth.token.claims.user_id,
                            total: cartItem.price
                        };

                        // Add payment record
                        await addDoc(collection(this.db, 'payments'), paymentData);

                        // Update user's subscription
                        const userRef = doc(this.db, 'users', this.selectedUser.id);
                        await updateDoc(userRef, {
                            endOfSubscription: paymentData.endOfSubscription,
                            plan: parseInt(paymentData.amount)
                        });

                        totalRevenue += cartItem.price;
                        subscriptionPrice += cartItem.price;
                    } else {
                        // Handle regular product
                        const itemRef = doc(this.db, "items", cartItem.id);
                        const newStock = cartItem.stock - cartItem.quantity;
                        const itemRevenue = cartItem.price * cartItem.quantity;
                        totalRevenue += itemRevenue;
                        totalWithoutSubscription += itemRevenue;

                        // Update item stock
                        await updateDoc(itemRef, {
                            stock: newStock,
                            updatedAt: serverTimestamp(),
                        });

                        // Record stock change
                        await addDoc(collection(this.db, "stock"), {
                            itemId: cartItem.id,
                            date: serverTimestamp(),
                            change: -cartItem.quantity,
                            reason: "sale",
                            newStock: newStock,
                            revenue: itemRevenue,
                            userId: this.selectedUser.id,
                        });
                    }
                }

                // Create transaction record with payment method and separated totals
                const transactionRef = collection(this.db, "transactions");
                const transactionData = {
                    items: this.cart.map((item) => ({
                        id: item.id,
                        name: item.name,
                        price: parseFloat(item.price),
                        quantity: parseInt(item.quantity),
                    })),
                    userId: this.selectedUser.id,
                    total: parseFloat(this.total),
                    totalRevenue: totalRevenue,
                    totalWithoutSubscription: totalWithoutSubscription,
                    subscriptionPrice: subscriptionPrice,
                    date: serverTimestamp(),
                    paymentMethod: this.paymentMethod,
                    cardType: this.paymentMethod === 'TARJETA' ?
                        `${this.cardPaymentType} ${this.cardType}` :
                        null
                };
                await addDoc(transactionRef, transactionData);

                // Reset payment-related fields
                this.paymentMethod = null;
                this.cardType = null;
                this.cardPaymentType = null;

                logAuditEvent('create', this.$store.state.Auth.token.claims.user_id,
                    `Successful sale for user ${this.selectedUser.id}. Total: $${totalRevenue}. Items: ${this.cart.length}`);

                this.$notify({
                    title: 'Venta exitosa',
                    type: 'success'
                });
                this.cart = [];
                this.selectedUser = null;
                this.selectedUsers = [];
                await this.fetchItems(); // Refresh items to update stock
            } catch (error) {
                console.error('Error durante el checkout:', error);
                this.$notify({
                    type: 'Error',
                    message: 'Error al procesar la venta',
                });
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
                    `Checkout error for user ${this.selectedUser.id}: ${error.message}`);
            } finally {
                this.loading.checkout = false;
                this.checkoutDialog = false;
            }
        },

        calculateEndOfSubscription(subscriptionData) {
            let endOfSubscription = moment();

            // Check if user has existing subscription
            if (this.selectedUser.endOfSubscription) {
                const existingEnd = this.selectedUser.endOfSubscription.toDate ?
                    this.selectedUser.endOfSubscription.toDate() :
                    new Date(this.selectedUser.endOfSubscription.seconds * 1000);
                endOfSubscription = moment(existingEnd);
            }

            // Add subscription months
            endOfSubscription = endOfSubscription.add(subscriptionData.months, "months");
            return Timestamp.fromDate(endOfSubscription.toDate());
        },

        addSubscriptionToCart({ days, months, price }) {
            try {
                const existingSubscription = this.cart.find(item => item.isSubscription);
                if (existingSubscription) {
                    this.$notify({
                        title: 'Advertencia',
                        message: 'Solo se puede agregar una suscripción al carrito. Por favor, elimine la suscripción existente primero.',
                        type: 'warning'
                    });
                    return;
                }

                const subscriptionName = days === 7 ?
                    `Pase Libre ${months} ${months === 1 ? 'mes' : 'meses'}` :
                    `Plan ${days} días - ${months} ${months === 1 ? 'mes' : 'meses'}`;

                this.cart.push({
                    id: `subscription_${days}_${months}`,
                    name: subscriptionName,
                    price: parseFloat(price),
                    quantity: 1,
                    stock: 999999,
                    isSubscription: true,
                    subscriptionData: {
                        days: days === 7 ? 0 : days,
                        months
                    }
                });

                logAuditEvent('create', this.$store.state.Auth.token.claims.user_id,
                    `Added subscription to cart: ${subscriptionName}`);

                this.$notify({
                    title: 'Suscripción agregada al carrito',
                    type: 'success'
                });
            } catch (error) {
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
                    `Error adding subscription to cart: ${error.message}`);
                this.$notify({
                    title: 'Error',
                    message: 'Error al agregar la suscripción',
                    type: 'error'
                });
            }
        },

        resetSelection() {
            this.selectedUser = null;
            this.selectedUsers = [];
            this.cart = [];
            this.paymentMethod = null;
            this.cardType = null;
            this.cardPaymentType = null;
            // Use router.back() instead of push
            this.$router.back();
        },

        // Add this new method to handle route changes
        handleRouteChange(to, from) {
            // If we're navigating away from a user selection
            if (from && from.params && from.params.userId && !to.params.userId) {
                this.selectedUser = null;
                this.selectedUsers = [];
                this.cart = [];
                this.paymentMethod = null;
                this.cardType = null;
                this.cardPaymentType = null;
            }
        },
    },

    // Update the route watcher
    watch: {
        '$route': {
            immediate: true,
            handler(to, from) {
                this.handleRouteChange(to, from);
                // Check for userId parameter
                const userId = to.params.userId;
                if (userId && !this.selectedUser) {
                    this.loadUserFromId(userId);
                }
            }
        }
    }
};
</script>

<style scoped>
.sticky-cart {
    position: sticky;
    top: 24px;
}

.mobile-full-width {
    width: 100%;
}

@media (max-width: 600px) {
    .sticky-cart {
        position: relative;
        top: 0;
    }
}

/* Only apply fill-height when container has the class */
.fill-height {
    min-height: calc(100vh - 64px);
}

/* Add this to remove the fill-height behavior when showing main content */
.v-container:has(.v-row:not([justify="center"])) {
    height: auto;
    min-height: 0;
}

/* Add these new styles */
.position-relative {
    position: relative;
}

.hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;
}
</style>